const ONE_SEC_IN_MILLISECONDS = 1000
const ONE_MINUTE_IN_SECONDS = 60
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS * 60
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24
const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7
const ONE_MONTH_IN_SECONDS = ONE_DAY_IN_SECONDS * 30
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS * 365

const ONE_WEEK_IN_DAYS = 7
const ONE_MONTH_IN_DAYS = 30
const ONE_YEAR_IN_DAYS = 365

const REFETCH_INTERVAL_IN_MILLISECONDS =
  ONE_SEC_IN_MILLISECONDS * ONE_MINUTE_IN_SECONDS * 5

const DATE_FORMAT_LOCALE_TAG = "us-US"

// 7 days
const ACRE_SESSION_EXPIRATION_TIME =
  ONE_WEEK_IN_SECONDS * ONE_SEC_IN_MILLISECONDS

export default {
  ONE_SEC_IN_MILLISECONDS,
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_WEEK_IN_SECONDS,
  ONE_MONTH_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ONE_WEEK_IN_DAYS,
  ONE_MONTH_IN_DAYS,
  ONE_YEAR_IN_DAYS,
  REFETCH_INTERVAL_IN_MILLISECONDS,
  DATE_FORMAT_LOCALE_TAG,
  ACRE_SESSION_EXPIRATION_TIME,
}
